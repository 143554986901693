export default {
  server: 'https://ishishita.dev.dialogplay.jp/api',
  messagingServer: 'https://ishishita.dev.dialogplay.jp/api',
  importantMessagesURLs: {
    json: '/important.json',
  },
  IdentityPoolId: 'ap-northeast-1:39900eea-d15a-4bd2-87db-fdbe4c8bdf2b',
  awsIotEndpoint: 'aaa9rccpxowt-ats.iot.ap-northeast-1.amazonaws.com',
  paymentStoreId: 'CHANGE_TO_STORE_ID_IN_ROBOT_PAYMENT',
  isExperimental: false,
  voiceSynthServer: 'https://voicevox.dev.dialogplay.jp',
  voiceSynthAccessToken: '8F9dJnGMYpc4RjZ7',
}
